import React from 'react';

import Layout from '../components/layout';

const FourOhFour = () => (
  <Layout title="Page not found">
    <p>
      Well... this shouldn&apos;t have happened. Do us a favor and let us know
      how you got here and we&apos;ll fix the bug that led you here. Thanks!
    </p>
  </Layout>
);

export default FourOhFour;
